import { required, minValue, maxValue , minLength } from "@vuelidate/validators";

import Competencia from './competencia';
import Profissional from './profissional';
import Cbo from './cbo';
import Estabelecimento from './estabelecimento';

export default class Usuario {
  constructor() {
    this.id = null;
    this.ch = 0;
    this.salarioBase = "";
    this.insalubridade = 0;
    this.addNoturno = 0;
    this.vfpg = 0;
    this.vpvt = 0;
    this.engTrabalista = 0;
    this.engPatronais = 0;
    this.remuneracaoTotal = 0;
    this.complemento = null;
    this.observacao = null;

    this.competencia = new Competencia();
    this.profissional = new Profissional();
    this.cbo = new Cbo();
    this.estabelecimento = new Estabelecimento();

  }
  validations() {
    return {
      ch: {
        required,
        minValue: minValue(20),
        maxValue: maxValue(88),
      },

      salarioBase: {
        required,
      },

      competencia: {
        id: {
          required,
        },

      },
      profissional: {
        nome: {
          required,
        },
        cpf: {
          minLength: minLength(11),
          required,
        },
      },

      cbo: {
        id: {
          required,
        },
      },

      estabelecimento: {
        id: {
          required,
        },
      },
    };
  }
}
