import { required } from "@vuelidate/validators";

export default class Cbo {
  constructor() {
    this.id = null;
    this.codigo = null;
    this.descricao = null;
   
  }
  validations() {
    return {
      codigo: {
        required,
      },
      descricao: {
        required,
      },
    };
  }

}
