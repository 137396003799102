<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '1400px' }"
    header="Lançamento de Profissionais"
    :modal="true"
    @hide="hideDialog"
  >
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-4">
        <label for="cnes">Estabelecimento</label>
        <Dropdown
          id="cnes"
          v-model="v$.lancamento.estabelecimento.id.$model"
          :options="estabelecimentos"
          optionLabel="cnes"
          optionValue="id"
          placeholder="Selecione um Estabelecimento."
          :filter="true"
          filterPlaceholder="Para buscar digite o numero do CNES"
          :class="{
            'p-invalid': submitted && v$.lancamento.estabelecimento.id.$invalid,
          }"
        >
          <template #option="slotProps">
            <small>
              <b>{{ slotProps.option.cnes }} </b> - {{ slotProps.option.nome }}
            </small>
            <br />
            <small>
              {{ slotProps.option.cnpj }}
            </small>
          </template>
        </Dropdown>
        <small
          class="p-error"
          v-if="submitted && v$.lancamento.estabelecimento.id.$invalid"
          >Estabelecimento é obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-4">
        <label for="nome">Nome</label>

        <InputText
          id="nome"
          v-model="v$.lancamento.profissional.nome.$model"
          maxlength="255"
          placeholder="Digite o nome do Funcionário."
          :class="{
            'p-invalid': submitted && v$.lancamento.profissional.nome.$invalid,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && v$.lancamento.profissional.nome.$invalid"
          >Nome é obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-4">
        <label for="cpf">CPF</label>
        <InputMask
          id="cpf"
          v-model="v$.lancamento.profissional.cpf.$model"
          mask="999.999.999-99"
          placeholder="Digite o CPF"
          :unmask="true"
          :class="{
            'p-invalid': submitted && v$.lancamento.profissional.cpf.$invalid,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && v$.lancamento.profissional.cpf.$invalid"
          >CPF é obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-4">
        <label for="cbo">CBO</label>
        <div>
          <Dropdown
            id="cbo"
            v-model="v$.lancamento.cbo.id.$model"
            :options="cbos"
            optionLabel="descricao"
            optionValue="id"
            placeholder="Selecione o CBO"
            :filter="true"
            filterPlaceholder="Para buscar digite a descrição CBO"
            :class="{
              'p-invalid': submitted && v$.lancamento.cbo.id.$invalid,
            }"
          >
            <template #option="slotProps">
              <small>
                <b>{{ slotProps.option.codigo }} </b> -
                {{ slotProps.option.descricao.toUpperCase() }}
              </small>
            </template>
          </Dropdown>
          <small
            class="p-error"
            v-if="submitted && v$.lancamento.cbo.id.$invalid"
            >CBO é obrigatório.</small
          >
        </div>
      </div>
      <div class="field col-12 md:col-4">
        <label for="ch">Carga Horária (SEMANAL)</label>
        <div>
          <InputNumber
            id="ch"
            v-model="v$.lancamento.ch.$model"
            inputId="withoutgrouping"
            :useGrouping="false"
            :min="20"
            :max="88"
            showButtons
            placeholder="Digite a Carga Horária."
            :class="{
              'p-invalid': submitted && v$.lancamento.ch.$invalid,
            }"
          />
          <small class="p-error" v-if="submitted && v$.lancamento.ch.$invalid"
            >Carga horária é obrigatória. Mínima 20 e Máximo 88</small
          >
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <label for="salario">Salário Base (MENSAL)</label>
        <div>
          <InputNumber
            id="salario"
            v-model="v$.lancamento.salarioBase.$model"
            inputId="currency-brazilian"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            placeholder="R$ 0,00"
            :min="0"
            :onfocusout="calculateRemunetacaoTotal(lancamento.salarioBase,'salarioBase')"
            :class="{
              'p-invalid': submitted && v$.lancamento.salarioBase.$invalid,
            }"
          />
          <small
            class="p-error"
            v-if="submitted && v$.lancamento.salarioBase.$invalid"
            >Salário base é obrigatória.</small
          >
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <label for="insalubridade">Insalubridade</label>
        <div>
          <InputNumber
            id="insalubridade"
            v-model="lancamento.insalubridade"
            inputId="currency-brazilian"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            placeholder="R$ 0,00"
            :min="0"
            :onfocusout="calculateRemunetacaoTotal(lancamento.insalubridade,'insalubridade')"
          />
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <label for="noturno">Adicional Noturno</label>
        <div>
          <InputNumber
            id="noturno"
            v-model="lancamento.addNoturno"
            inputId="currency-brazilian"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            placeholder="R$ 0,00"
            :min="0"
            :onfocusout="calculateRemunetacaoTotal(lancamento.addNoturno,'addNoturno')"
          />
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <label for="noturno">Vantagens de Caráter fixo (VFPG)</label>
        <div>
          <InputNumber
            id="noturno"
            v-model="lancamento.vfpg"
            inputId="currency-brazilian"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            placeholder="R$ 0,00"
            :min="0"
            :onfocusout="calculateRemunetacaoTotal(lancamento.vfpg,'vfpg')"
          />
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <label for="noturno">Vantagens Variáveis (VPVT)</label>
        <div>
          <InputNumber
            id="noturno"
            v-model="lancamento.vpvt"
            inputId="currency-brazilian"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            placeholder="R$ 0,00"
            :min="0"
            :onfocusout="calculateRemunetacaoTotal(lancamento.vpvt,'vpvt')"
          />
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <label for="noturno">Encargos Trabalhistas</label>
        <div>
          <InputNumber
            id="noturno"
            v-model="lancamento.engTrabalista"
            inputId="currency-brazilian"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            placeholder="R$ 0,00"
            :min="0"
            :onfocusout="calculateRemunetacaoTotal(lancamento.engTrabalista,'engTrabalista')"
          />
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <label for="noturno">Encargos Patronais</label>
        <div>
          <InputNumber
            id="noturno"
            v-model="lancamento.engPatronais"
            inputId="currency-brazilian"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            placeholder="R$ 0,00"
            :min="0"
            :onfocusout="calculateRemunetacaoTotal(lancamento.engPatronais,'engPatronais')"
          />
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <label for="encargo">Remuneração Mensal Total</label>
        <InputNumber
          id="encargo"
          v-model="lancamento.remuneracaoTotal"
          inputId="currency-brazilian"
          mode="currency"
          currency="BRL"
          locale="pt-BR"
          placeholder="R$ 0,00"
          disabled
          :onfocusout="calculateRemunetacaoTotal(lancamento.remuneracaoTotal,'remuneracaoTotal')"
        />
      </div>
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.lancamento.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
  <script>
//Models
import Lancamento from "../../../models/lancamento";

//Services
import LancamentoService from "../../../service/lancamento/lancamento_service";
import CboService from "../../../service/cbo/cbo_service";
import EstabelecimentoService from "../../../service/estabelecimento/estabelecimento_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["competenciaSelected", "lancamentoSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      //START VARIABLES
      cbos: [],
      estabelecimentos: [],
      submitted: false,
      //START MODELS
      lancamento: new Lancamento(),
      //START SERVICE
      lancamentoService: new LancamentoService(),
      cboService: new CboService(),
      estabelecimentoService: new EstabelecimentoService(),
    };
  },
  mounted() {
    this.findCbos();
    this.findEstabelecimentosToUser();
  },
  validations() {
    return {
      lancamento: new Lancamento().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.lancamento.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.lancamento.dialogForm = value;
      },
    },
  },
  methods: {
    getData() {
      this.lancamento = this.lancamentoSelected;
      this.lancamento.competencia = this.competenciaSelected;
      console.log(this.lancamento)
    },
    send(isFormValid) {
      this.submitted = true;
      console.log(this.lancamento)
      if (isFormValid) {
        if (this.lancamento.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.lancamento.profissional.nome.toUpperCase();
      this.lancamentoService
        .create(this.lancamento)
        .then((data) => {
          this.$msgSuccess(data);
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.lancamentoService
        .update(this.lancamento)
        .then((data) => {
          this.$msgSuccess(data);
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    findCbos() {
      this.cboService.findAll().then((data) => {
        this.cbos = data;
      });
    },
    findEstabelecimentosToUser() {
      /*SE FOR ADMIN */
      if (this.$Token.jwtDecode().grupo.toUpperCase() === "ADMINISTRADOR") {
        this.estabelecimentoService.findAll().then((data) => {
          this.estabelecimentos = data;
        });
        /* SE NÃO FOR PEGA DO TOKEN */
      } else {
        this.estabelecimentos = this.$Token.jwtDecode().estabelecimentos;
      }
    },
    hideDialog() {
      this.lancamento = new Lancamento();
      this.submitted = false;
      this.$emit("findAll");
      this.visibleDialog = false;
    },
    calculateRemunetacaoTotal(p1,p2) {
      this.lancamento[`${p2}`] = (p1 === null) || (p1 === undefined) ? 0 : p1 /*  this.checkZero(p1)*/;

      this.lancamento.remuneracaoTotal =
        this.lancamento.salarioBase +
        this.lancamento.insalubridade +
        this.lancamento.addNoturno +
        this.lancamento.vfpg +
        this.lancamento.vpvt +
        this.lancamento.engTrabalista +
        this.lancamento.engPatronais;

      return this.lancamento.remuneracaoTotal;
    },
  },
};
</script>
  <style scoped></style>
  