import axios from "axios";

import Token from "../../utilities/jwt/Token.js";

import { gerarUrl } from "../../utilities/utils/Pageable";

export default class LancamentoService extends Token {

  export(idCompetencia, cbo, cnes) {

    return axios({
      method: "get",
      url: this.api_url + `lancamento/export?p1=${idCompetencia}&p2=${cbo}&p3=${cnes}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
      responseType: "blob"
    }).then((res) => res);
    //window.open(`${this.api_url}lancamento/export?p1=${idCompetencia}&p2=${cbo}&p3=${cnes}`, "_blank");
  }

  findAll(queryParams) {

    queryParams = gerarUrl(queryParams);

    return axios({
      method: "get",
      url: this.api_url + "lancamento?" + queryParams,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  create(lancamento) {
    return axios({
      method: "post",
      url: this.api_url + "lancamento",
      withCredentials: true,
      data: lancamento,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  criarLancamentos(lancamento) {
    return axios({
      method: "post",
      url: this.api_url + "lancamento/criar-lancamentos",
      withCredentials: true,
      data: lancamento,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  update(lancamento) {
    return axios({
      method: 'put',
      url: this.api_url + 'lancamento/' + lancamento.id,
      withCredentials: true,
      data: lancamento,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'lancamento/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }

  deleteByCompetenciaId(competenciaId) {
    return axios({
      method: 'delete',
      url: this.api_url + 'lancamento/excluir-lancamentos/' + competenciaId,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
}
