import axios from "axios";

import Token from "../../utilities/jwt/Token.js";

export default class CompetenciaService extends Token {
  findAll() {
    return axios({
      method: "get",
      url: this.api_url + "competencia",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }
  
  findAllAtivos() {
    return axios({
      method: "get",
      url: this.api_url + "competencia/ativos",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  findAllBaseRef() {
    return axios({
      method: "get",
      url: this.api_url + "competencia/base-referencia",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  create(competencia) {
    return axios({
      method: "post",
      url: this.api_url + "competencia",
      withCredentials: true,
      data: competencia,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }
  update(competencia) {
    return axios({
      method: 'put',
      url: this.api_url + 'competencia/' + competencia.id,
      withCredentials: true,
      data: competencia,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  updateStatus(competencia) {
    return axios({
      method: 'put',
      url: this.api_url + 'competencia/atualizar-status/' + competencia.id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'competencia/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
}
