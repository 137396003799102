import { required } from "@vuelidate/validators";

export default class Competencia {

    constructor() {
        this.id = null;
        this.ano = null;
        this.mes = null;
        this.retificacao = null;
        this.visualizacao = null;
        this.dtInicio = null;
        this.dtTermino = null;
        this.baseRef = null
    }

    validations() {
        return {
            ano: {
                required,
            },
            mes: {
                required,
               // regex: regex(/^[0-9]+(-[0-9]+)?$/)
            },
            dtInicio: {
                required,
            },
        };
    }

}
