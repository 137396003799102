import { required, minLength } from "@vuelidate/validators";

export default class Profissional {
  constructor() {
    this.id = null;
    this.nome = null;
    this.cpf = null;
   
  }
  validations() {
    return {
      nome: {
        required,
      },
      cpf: {
        minLength: minLength(11),
        required
      },
    };
  }

}
