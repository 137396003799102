<template>
  <div class="col-12">
    <Panel header="Lançamento de Profissionais">
      <form @submit.prevent="send">
        <Card>
          <template #content>
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-4">
                <label for="competencia">Competência</label>
                <Dropdown
                  id="competencia"
                  v-model="lazyParams.competencia"
                  :options="competencias"
                  optionLabel="descricao"
                  optionValue="id"
                  placeholder="Informe o mes/ano de forma numérica."
                  filterPlaceholder="Para buscar digite o numero do CNES"
                  :filter="true"
                  @change="checkSelectedCompetencia($event)"
                >
                  <template #option="slotProps">
                    <small>
                      {{ slotProps.option.descricao }}
                    </small>
                    <br />
                    <small>
                      <b>
                        PERÍODO:
                        {{
                          $DateTime.formatarDateTime24hours(
                            slotProps.option.dtInicio
                          )
                        }}
                        -
                        {{
                          $DateTime.formatarDateTime24hours(
                            slotProps.option.dtTermino
                          )
                        }}
                      </b>
                    </small>
                    <br />
                    <small
                      :class="
                        'user-badge status-' +
                        $DateTime.isBetween(
                          slotProps.option.dtInicio,
                          slotProps.option.dtTermino
                        ).status
                      "
                    >
                      <b>
                        {{
                          $DateTime.isBetween(
                            slotProps.option.dtInicio,
                            slotProps.option.dtTermino
                          ).texto
                        }}
                      </b>
                    </small>
                  </template>
                </Dropdown>
              </div>

              <div class="field col-12 md:col-4">
                <label for="cnes">CNES (Estabelecimento)</label>
                <MultiSelect
                  id="cnes"
                  v-model="lazyParams.estabelecimentos"
                  :options="estabelecimentos"
                  optionLabel="cnes"
                  optionValue="cnes"
                  placeholder="Selecione um  CNES (estabelecimento)."
                  :filter="true"
                  filterPlaceholder="Para buscar digite o numero do CNES"
                  :class="{
                    'p-invalid':
                      submitted && v$.profissional.estabelecimento.id.$invalid,
                  }"
                >
                  <template #option="slotProps">
                    <small>
                      <b>{{ slotProps.option.cnes }} </b> -
                      {{ slotProps.option.nome }}
                    </small>
                    <br />
                    <small>
                      {{ slotProps.option.cnpj }}
                    </small>
                  </template>
                </MultiSelect>
              </div>

              <div class="field col-12 md:col-4">
                <label for="cbo">CBO</label>
                <div>
                  <MultiSelect
                    id="cbo"
                    v-model="lazyParams.cbo"
                    :options="cbos"
                    optionLabel="descricao"
                    optionValue="codigo"
                    placeholder="Selecione o CBO"
                    :filter="true"
                    filterPlaceholder="Para buscar digite a descrição CBO"
                  >
                    <template #option="slotProps">
                      <small>
                        <b>{{ slotProps.option.codigo }} </b> -
                        {{ slotProps.option.descricao.toUpperCase() }}
                      </small>
                    </template>
                  </MultiSelect>
                </div>
              </div>

              <div class="field col-12 md:col-4">
                <label for="nome">Nome</label>
                <InputText
                  id="nome"
                  v-model="lazyParams.nome"
                  autofocus
                  placeholder="Digite o nome do profissional"
                />
              </div>

              <div class="field col-12 md:col-4">
                <label for="cpf">CPF</label>
                <InputMask
                  id="cpf"
                  v-model="lazyParams.cpf"
                  mask="999.999.999-99"
                  placeholder="Digite o CPF"
                  :unmask="true"
                />
              </div>
            </div>
          </template>
          <template #footer>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0"></h5>
              <div class="flex gap-2 mt-1">
                <Button
                  label="Pesquisar"
                  class="p-button-raised"
                  icon="pi pi-search"
                  type="submit"
                />
                <Button
                  label="Limpar"
                  icon="pi pi-times"
                  class="p-button-raised p-button-text"
                  @click="cleanAll()"
                />
              </div>
            </div>
          </template>
        </Card>
      </form>
      <DataTable
        :value="lancamentos"
        :lazy="true"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[10, 20, 30]"
        ref="dt"
        class="p-datatable-sm mt-2"
        dataKey="id"
        :totalRecords="totalRecords"
        :loading="loading"
        @page="onPage($event)"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} registros."
      >
        <template #empty>
          <div class="text-center">Nenhum resultado encontrado...</div>
        </template>

        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
          >
            <h5 class="m-0">
              {{ competencia.descricao }}
            </h5>
            <IconField iconPosition="left" class="block mt-2 md:mt-0">
              <Button
                label="Exportar"
                icon="pi pi-file-excel"
                class="p-button-raised p-button-success mr-2"
                @click="send(true)"
                v-if="lancamentos.length !== 0"
              />
              <Button
                label="Novo"
                icon="pi pi-plus"
                class="p-button-raised"
                v-if="visible"
                @click="showCreate()"
              />
            </IconField>
          </div>
        </template>

        <Column header="CNPJ">
          <template #body="slotProps">
            {{ slotProps.data.estabelecimento.cnpj }}
          </template>
        </Column>

        <Column header="Nome do Empregador">
          <template #body="slotProps">
            {{ slotProps.data.estabelecimento.nome.toUpperCase() }}
          </template>
        </Column>

        <Column header="CNES">
          <template #body="slotProps">
            {{ slotProps.data.estabelecimento.cnes }}
          </template>
        </Column>

        <Column header="Nome do Profissional">
          <template #body="slotProps">
            {{ slotProps.data.profissional.nome.toUpperCase() }}
          </template>
        </Column>

        <Column header="CPF">
          <template #body="slotProps">
            {{ formatCpf(slotProps.data.profissional.cpf) }}
          </template>
        </Column>

        <Column header="CBO">
          <template #body="slotProps">
            {{ slotProps.data.cbo.descricao.toUpperCase() }}
          </template>
        </Column>

        <Column header="Ações">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="showUpdate(slotProps.data)"
              v-if="visible"
              v-tooltip.top="'CLIQUE PARA EDITAR'"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="showRemove(slotProps.data)"
              v-if="visible"
              v-tooltip.top="'CLIQUE PARA EXCLUIR'"
            />
          </template>
        </Column>
      </DataTable>
    </Panel>
  </div>

  <ConfirmDialog></ConfirmDialog>

  <dialog-form
    :lancamentoSelected="lancamento"
    :competenciaSelected="competencia"
    @findAll="send"
  />
</template>

<script>
//models
import Lancamento from "../../models/lancamento";
import Competencia from "../../models/competencia";

//Services
import CboService from "../../service/cbo/cbo_service";
import EstabelecimentoService from "../../service/estabelecimento/estabelecimento_service";
import CompetenciaService from "../../service/competencia/competencia_service";
import LancamentoService from "../../service/lancamento/lancamento_service";

//components
import DialogForm from "./components/dialog-form.vue";

export default {
  components: {
    DialogForm,
  },
  data() {
    return {
      loading: false,
      visible: false,

      lancamentos: [],
      competencias: [],
      cbos: [],
      estabelecimentos: [],

      lancamentoService: new LancamentoService(),
      competenciaService: new CompetenciaService(),
      cboService: new CboService(),
      estabelecimentoService: new EstabelecimentoService(),

      lazyParams: {},
      totalRecords: 0,
      lancamento: new Lancamento(),
      competencia: new Competencia(),
    };
  },
  mounted() {
    this.lazyParams = {
      competencia: null,
      nome: "",
      cpf: "",
      cbo: [],
      estabelecimentos: [],
      first: 0,
      size: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
    };
    this.findCompetencias();
    this.findCbos();
    this.findEstabelecimentosToUser();
  },
  methods: {
    showCreate() {
      this.lancamento = new Lancamento();
      this.$store.state.views.lancamento.dialogForm = true;
    },
    showUpdate(lancamento) {
      this.lancamento = { ...lancamento };
      this.$store.state.views.lancamento.dialogForm = true;
    },
    showRemove(lancamento) {
      this.$confirm.require({
        message: "Deseja Excluir esse registro?",
        header: "Deseja deletar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.lancamentoService
            .delete(lancamento.id)
            .then((data) => {
              this.$msgSuccess(data);
              this.send();
            })
            .catch((error) => {
              this.$msgErro(error);
            });
        },
      });
    },
    findCompetencias() {
      this.competenciaService.findAllAtivos().then((data) => {
        this.competencias = data;
      });
    },
    findCbos() {
      this.cboService.findAll().then((data) => {
        this.cbos = data;
      });
    },
    findEstabelecimentosToUser() {
      /*SE FOR ADMIN */
      if (this.$Token.jwtDecode().grupo.toUpperCase() === "ADMINISTRADOR") {
        this.estabelecimentoService.findAll().then((data) => {
          this.estabelecimentos = data;
        });
        /* SE NÃO FOR PEGA DO TOKEN */
      } else {
        this.estabelecimentos = this.$Token.jwtDecode().estabelecimentos;
      }
    },
    exportInvestsus() {
      this.loading = true;
      this.lancamentoService
        .export(
          this.lazyParams.competencia,
          this.lazyParams.cbo,
          this.lazyParams.estabelecimentos
        )
        .then((response) => {
          if (response.status == 200) {
            let blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            let downloadUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            //a.style.display = "none";
            a.href = downloadUrl;
            a.download = `PLANILHA DE CARGA ${
              this.competencia.descricao
            } GERADO EM ${this.$DateTime.formatarDateTime24hours(
              new Date()
            )}.xls`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(downloadUrl);
            this.loading = false;
          }
          //console.log(data);
        })
        .catch((error) => {
          this.$msgErro(error);
          this.loading = false;
        });
    },
    send(exportData = false) {
      if (
        this.lazyParams.estabelecimentos.length === 0 ||
        this.lazyParams.cbo.length === 0 ||
        this.lazyParams.competencia === null
      ) {
        this.$msgInfo(
          "Para efetuar a pesquisa, " +
            "é necessário selecionar UMA COMPETÊNCIA com pelo menos UM ESTABELECIMENTO e UM CBO!",
          "Atenção!"
        );
        return;
      }

      exportData =
        exportData === true ? this.exportInvestsus() : this.loadLazy();

      return exportData;
    },
    sendExport() {
      this.lazyParams.competencia === null
        ? this.$msgInfo("Selecione uma competência para exportar.", "Atenção.")
        : this.exportInvestsus(this.lazyParams.competencia);
    },
    loadLazy() {
      this.loading = true;
      this.lancamentoService.findAll(this.lazyParams).then((data) => {
        if (data.content.length === 0) {
          this.$msgInfo(
            "Desculpe! Não foram encontrados registros para essa busca. Tente novamente!",
            "Atenção!"
          );
          this.lancamentos = [];
          this.loading = false;
          return;
        }
        this.lancamentos = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
      });
    },
    onPage(event) {
      this.lazyParams.first = event.originalEvent.first;
      this.lazyParams.page = event.originalEvent.page;
      this.lazyParams.size = event.rows;
      this.send();
    },
    formatCpf(cpf) {
      //retira os caracteres indesejados...
      cpf = cpf.replace(/[^\d]/g, "");
      //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    cleanAll() {
      this.lazyParams = {
        competencia: null,
        nome: "",
        cpf: "",
        cbo: [],
        estabelecimentos: [],
        first: 0,
        size: this.$refs.dt.rows,
        sortField: null,
        sortOrder: null,
      };
      this.competencia = new Competencia();
      this.totalRecords = 0;
      this.lancamentos = [];
      this.loading = false;
    },
    checkSelectedCompetencia(event) {
      this.competencia = this.competencias.find(
        (competencia) => competencia.id === event.value
      );
      this.lancamentos = [];
      this.send();

      this.visible =
        this.$Token.jwtDecode().grupo.toUpperCase() === "ADMINISTRADOR"
          ? true
          : this.$DateTime.isBetween(
              this.competencia.dtInicio,
              this.competencia.dtTermino
            ).status;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../App.scss");
</style>